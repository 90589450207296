<template>
  <div id="module">
    <v-toolbar id="module-toolbar" absolute>
      <v-toolbar-title
        ><slot name="title">{{ title }}</slot></v-toolbar-title
      >

      <v-spacer />

      <div v-if="canClearFilters">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              id="clearFilters"
              class="ml-10 button"
              fab
              rounded
              :disabled="disabled"
              @click="clearFilters"
              data-cy-id="clear-filters-button"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-filter-remove-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("global.action.clear-filters") }}</span>
        </v-tooltip>
      </div>

      <div v-if="canDelete">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              id="delete"
              class="ml-10 button"
              color="error"
              fab
              rounded
              :disabled="disabled"
              @click="initiateDelete"
              data-cy-id="delete-button"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>pic-delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("global.tooltip.effisoftToolbarDelete") }}</span>
        </v-tooltip>
      </div>

      <div v-if="canList">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              id="edit"
              class="ml-10 button"
              fab
              rounded
              @click="list($event)"
              data-cy-id="list-button"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>pic-enumerate</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("global.tooltip.effisoftToolbarList") }}</span>
        </v-tooltip>
      </div>

      <div v-if="canCopy">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              id="copy"
              class="ml-10 button"
              fab
              rounded
              @click="copy($event)"
              data-cy-id="copy-button"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>pic-duplicate</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("global.tooltip.effisoftToolbarCopy") }}</span>
        </v-tooltip>
      </div>

      <div v-if="canImport">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              id="import"
              class="ml-10 button"
              fab
              rounded
              @click="importItem($event)"
              data-cy-id="import-button"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>pic-import</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("global.modules.import") }}</span>
        </v-tooltip>
      </div>

      <div v-if="canDownload">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              id="download"
              class="ml-10 button"
              fab
              rounded
              @click="downloadItem($event)"
              data-cy-id="download-button"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>pic-import</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("global.action.download") }}</span>
        </v-tooltip>
      </div>

      <div v-if="canUpload">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              id="upload"
              class="ml-10 button"
              fab
              rounded
              @click="uploadItem($event)"
              data-cy-id="upload-button"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>pic-export</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("global.action.upload") }}</span>
        </v-tooltip>
      </div>

      <div v-if="canExport">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              id="export"
              class="ml-10 button"
              fab
              rounded
              @click="exportItem($event)"
              data-cy-id="export-button"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>pic-export</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("global.action.export") }}</span>
        </v-tooltip>
      </div>

      <div v-if="hasTreeToggle">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              id="tree-toggle"
              class="ml-10 button"
              fab
              rounded
              @click="toggleTree($event)"
              data-cy-id="tree-toggle-button"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon v-if="treeEnabled">mdi-book-open</v-icon>
              <v-icon v-else>mdi-book-open-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ treeToggleToolTip }}</span>
        </v-tooltip>
      </div>

      <div v-if="canEdit">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              id="edit"
              class="ml-10 button"
              fab
              rounded
              @click="editItem($event)"
              data-cy-id="edit-button"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>pic-edit</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("global.tooltip.effisoftToolbarEdit") }}</span>
        </v-tooltip>
      </div>

      <div v-if="canCreate">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              id="new"
              class="ml-10 button"
              fab
              rounded
              @click="newItem($event)"
              data-cy-id="new-button"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>pic-add</v-icon>
            </v-btn>
          </template>
          <span>{{ createToolTipComp }}</span>
        </v-tooltip>
      </div>

      <div v-if="canLock">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              id="lock"
              class="ml-10 button"
              fab
              rounded
              @click="lock($event)"
              data-cy-id="lock-button"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>pic-lock</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("global.business-process.lock") }}</span>
        </v-tooltip>
      </div>

      <div v-if="canUnlock">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              id="unlock"
              class="ml-10 button"
              fab
              rounded
              @click="unlock($event)"
              data-cy-id="unlock-button"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>pic-unlock</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("global.business-process.unlock") }}</span>
        </v-tooltip>
      </div>

      <div v-if="$store.state.bpInstReadPermission">
        <v-menu open-on-hover offset-y>
          <template #activator="{ on }">
            <v-btn dark icon v-on="on">
              <v-icon>pic-history</v-icon>
            </v-btn></template
          >
          <v-list>
            <div v-if="$store.state.recentBps.length > 0">
              <v-list-item
                v-for="bpId in $store.state.recentBps"
                :key="bpId"
                @click="bpClicked(bpId)"
              >
                <v-chip color="orange" dark small> #{{ bpId }} </v-chip>
                <span class="ml-1">
                  {{ $store.state.recentBpsMap[bpId] }}
                </span>
              </v-list-item>
            </div>
            <v-list-item v-else>{{
              $t("global.concepts.no-recent-bps")
            }}</v-list-item>
          </v-list>
        </v-menu>
      </div>

      <account-menu dark-label />
    </v-toolbar>

    <!-- module content -->
    <div class="module-content">
      <slot></slot>
    </div>
    <v-dialog v-model="open" persistent :width="400">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("global.action.confirm") }}</span>
        </v-card-title>
        <v-card-text>
          <span>{{ confirmDeleteMessage }}</span>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            depressed
            data-cy-id="confirm-delete-button"
            color="error"
            class="is-action"
            @click="
              open = false;
              deleteItem($event);
            "
          >
            {{ $t("global.action.yes") }}
          </v-btn>
          <v-btn depressed class="button" @click="open = false">
            {{ $t("global.action.no") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AccountMenu from "@/components/layout/AccountMenu.vue";

export default {
  name: "module-template",

  components: {
    AccountMenu,
  },

  props: {
    canCreate: {
      type: Boolean,
      default: false,
    },

    canEdit: {
      type: Boolean,
      default: false,
    },

    canLock: {
      type: Boolean,
      default: false,
    },

    canUnlock: {
      type: Boolean,
      default: false,
    },

    canList: {
      type: Boolean,
      default: false,
    },
    canCopy: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },

    canClearFilters: {
      type: Boolean,
      default: false,
    },

    canSubmit: {
      type: Boolean,
      default: true,
    },

    canImport: {
      type: Boolean,
      default: false,
    },

    canDownload: {
      type: Boolean,
      default: false,
    },

    canUpload: {
      type: Boolean,
      default: false,
    },

    canExport: {
      type: Boolean,
      default: false,
    },

    hasTreeToggle: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    deleteMessage: {
      type: String,
      default: null,
    },
    isForm: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    deleteValidation: {
      type: Function,
      required: false,
    },

    createToolTip: {
      type: String,
    },

    treeEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      dialog: false,
    };
  },
  computed: {
    confirmDeleteMessage() {
      return this.deleteMessage
        ? this.deleteMessage
        : this.$t("global.action.confirm-continue");
    },

    createToolTipComp() {
      return this.createToolTip
        ? this.createToolTip
        : this.$t("global.tooltip.effisoftToolbarAdd");
    },

    recentBps() {
      return [{ value: "download", text: this.$t("global.action.download") }];
    },

    treeToggleToolTip() {
      return this.treeEnabled
        ? this.$t("global.action.close-left-panel")
        : this.$t("global.action.open-left-panel");
    },
  },

  methods: {
    bpClicked(bpId) {
      this.$router
        .push({ name: "BP instance", params: { bpId: bpId } })
        .catch((error) => {
          // Don't throw an error when the user navigates to the current route
          if (error.name !== "NavigationDuplicated") {
            throw error;
          }
        });
    },

    validate(event) {
      this.$emit("validate", event);
    },
    saveWithValidate(event) {
      this.$emit("save", event);
    },
    newItem(event) {
      this.$emit("newItem", event);
    },

    editItem(event) {
      this.$emit("editItem", event);
    },

    lock(event) {
      this.$emit("lock", event);
    },

    unlock(event) {
      this.$emit("unlock", event);
    },

    exportItem(event) {
      this.$emit("export", event);
    },

    toggleTree(event) {
      this.$emit("toggleTree", event);
    },

    importItem(event) {
      this.$emit("import", event);
    },

    downloadItem(event) {
      this.$emit("download", event);
    },

    uploadItem(event) {
      this.$emit("upload", event);
    },

    list(event) {
      this.$emit("list", event);
    },
    copy(event) {
      this.$emit("copyItem", event);
    },
    deleteItem(event) {
      this.$emit("deleteItem", event);
    },
    cancel(event) {
      this.$emit("cancel", event);
    },
    initiateDelete() {
      if (this.deleteValidation) {
        this.open = this.deleteValidation();
      } else {
        this.open = true;
      }
    },

    clearFilters() {
      this.$emit("clearFilters", event);
    },
  },
};
</script>

<style lang="scss">
#module-toolbar {
  $offset: 24px;
  position: sticky;
  z-index: 7;
  max-width: none;
  margin-left: -$offset;
  margin-top: -$offset;
  width: calc(100% + #{$offset * 2});

  div.v-text-field__slot {
    font-size: 17px;
  }
}
</style>
