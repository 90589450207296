<template>
  <div>
    <v-row dense class="mb-2">
      <v-col cols="auto">
        <basic-switch
          v-model="showAll"
          dense
          hide-details
          :label="$t('global.run.show-all')"
          reversed
        />
      </v-col>
      <v-col cols="auto">
        <basic-switch
          v-model="showPath"
          dense
          hide-details
          :label="$t('global.run.show-path')"
          reversed
        />
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <v-btn v-if="showUploadAsZip" @click="onUploadAsZip" color="primary">{{
          $t("global.run.upload-zip")
        }}</v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="showZipUploadInput" width="560">
      <v-overlay absolute :value="uploadingZip">
        <v-chip color="blue" dark>{{ $t("global.msg.uploading") }}</v-chip>
      </v-overlay>
      <v-card>
        <v-card-title>{{ $t("global.action.upload") }}</v-card-title>
        <v-card-text
          ><v-text-field
            disabled
            :value="run.fileEnvName"
            :label="$t('global.environment.environment')"
            persistent-placeholder
          ></v-text-field>
          <v-file-input
            show-size
            :label="$t('global.concepts.choose-file')"
            accept=".zip"
            truncate-length="100"
            v-model="uploadZip"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="onCancelUploadZip">{{
            $t("global.action.cancel")
          }}</v-btn>
          <v-btn
            color="primary"
            :disabled="!uploadZip"
            @click="onConfirmUploadAsZip"
            >{{ $t("global.action.upload") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showZipUploadReport" width="1200">
      <v-card>
        <v-card-title>{{
          $t("global.concepts.zip-import-report")
        }}</v-card-title>
        <v-card-text>
          <ooliba-basic-table
            :headers="zipReportHeaders"
            :items="zipReportItems"
            no-pagination
            sortBy="file"
          >
            <template #[`item.status`]="{ item }">
              <v-icon
                small
                class="pr-2 pt-1"
                v-if="item.uploaded"
                color="green"
              >
                pic-check
              </v-icon>
              <v-icon small class="pr-2 pt-1" v-else color="orange">
                pic-warning
              </v-icon>
              <span>{{ item.status }}</span>
            </template>
          </ooliba-basic-table>
        </v-card-text>

        <v-card-actions>
          <v-btn @click="showZipUploadReport = false">{{
            $t("global.action.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row dense>
      <v-col>
        <ooliba-basic-table
          :headers="headers"
          :items="itemsFiltered"
          :loading="busy"
          no-pagination
          sortBy="name"
        >
          <template #[`item.available`]="{ item }">
            <v-tooltip v-if="item.invalidDataMessages?.length > 0" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="pr-4" color="yellow" v-bind="attrs" v-on="on">
                  {{ `mdi-alert-outline` }}
                </v-icon>
              </template>
              <span v-for="(mess, i) in item.invalidDataMessages" :key="i">{{
                mess
              }}</span>
            </v-tooltip>
            <div v-else>
              <v-icon small class="pr-4" v-if="item.available" color="green">
                pic-check
              </v-icon>
              <v-icon small class="pr-4" v-else color="red"> pic-close </v-icon>
            </div>
          </template>

          <template #[`item.name`]="{ item }">
            <router-link
              v-if="item.available"
              class="no-underline"
              :to="`/file/view/${item.fileId}`"
              >{{ item.name }}</router-link
            >
          </template>

          <template #[`item.env`]="{ item }">
            <v-chip small outlined v-if="item.envIsLocal">LOCAL</v-chip>
            <file-env-chip
              v-if="!item.envIsLocal && item.env"
              :color="item.envColor"
              :label="item.env"
            >
            </file-env-chip>
          </template>

          <template #[`item.userInput`]="{ item }">
            <check-icon class="pr-4" :value="item.userInput" />
          </template>

          <template #[`item.action`]="{ item }">
            <div v-if="!(isRunning || isFinished)">
              <v-progress-circular :size="25" v-if="item.busy" indeterminate />
              <btn-menu
                class="my-1"
                v-else
                :items="
                  item.available ? itemAvailableActions : itemUnavailableActions
                "
                :text="$t('global.concepts.action')"
                @download="onDownload(item.fileUrl)"
                @upload="onUploadAction(item)"
                @pick-version="onPickVersion(item)"
                @pick-latest-version="onPickLatestVersion(item)"
              />
            </div>
            <v-icon v-if="isFinished" @click="onDownload(item.fileUrl)"
              >pic-import</v-icon
            >
          </template>
        </ooliba-basic-table>
      </v-col>
    </v-row>

    <v-dialog v-model="showFileVersion" persistent width="1200">
      <file-details
        v-model="showFileVersion"
        :name="pickVersionFileName"
        :env="pickVersionEnv"
        class="py-3"
        @pick-version="onVersionPicked"
      />
    </v-dialog>

    <v-dialog v-model="showUpload" width="1000">
      <upload-file
        :fileName="selectedFileInfo.name"
        :fileEnvName="run.fileEnvName"
        :uploading="uploading"
        @upload="onUpload($event)"
        @cancel="onCancel"
      ></upload-file>
    </v-dialog>
    <v-dialog v-model="showWarnings" width="500">
      <file-upload-warnings
        @cancel="onConfirmCancel"
        @confirmUpload="onConfirmUpload"
        :uploading="uploading"
        :warnings="warnings"
      ></file-upload-warnings>
    </v-dialog>
  </div>
</template>

<script>
import { get, downloadFile, put, postForm } from "@/model/api";

import BtnMenu from "@/components/BtnMenu";
import BasicSwitch from "@/components/BasicSwitch";

import CheckIcon from "@/components/CheckIcon";
import FileDetails from "@/components/run/FileDetails";

import OolibaBasicTable from "@/components/OolibaBasicTable";
import UploadFile from "@/components/UploadFile";
import FileUploadWarnings from "@/components/run/FileUploadWarnings";
import FileEnvChip from "@/components/FileEnvChip";

export default {
  name: "RunInputFiles",

  components: {
    BtnMenu,
    BasicSwitch,
    CheckIcon,
    FileDetails,
    OolibaBasicTable,
    UploadFile,
    FileUploadWarnings,
    FileEnvChip,
  },

  props: {
    run: {},
  },

  data() {
    return {
      busy: true,
      downloadHref: "",

      fileInfoes: [],
      filterList: {},

      showAll: false,
      showPath: false,
      showZipUploadInput: false,
      uploadZip: undefined,

      headers: [
        { text: this.$t("global.environment.env"), value: "env" },
        { text: this.$t("global.concepts.name"), value: "name" },
        { text: this.$t("global.concepts.version"), value: "version" },
        {
          text: this.$t("global.concepts.available"),
          value: "available",
          align: "center",
        },
        {
          text: this.$t("global.run.user-input"),
          value: "userInput",
          align: "center",
        },
        { text: this.$t("global.concepts.size"), value: "size" },
        {
          text: this.$t("global.concepts.action"),
          value: "action",
          align: "center",
          sortable: false,
        },
      ],

      zipReportHeaders: [
        { text: this.$t("global.concepts.filename"), value: "file" },
        { text: this.$t("global.concepts.status"), value: "status" },
      ],

      items: [],
      pickVersionFileName: null,
      pickVersionEnv: null,
      selectedFileInfo: {},
      showWarnings: false,
      warnings: [],
      uploadParameters: {},

      showFileVersion: false,
      showUpload: false,
      uploading: false,
      uploadingZip: false,
      zipReportItems: [],
      showZipUploadReport: false,
    };
  },

  computed: {
    showUploadAsZip() {
      return (
        !(
          this.$store.getters.isStatusRunning(this.run.status) ||
          this.$store.getters.isStatusFinished(this.run.status)
        ) && this.$store.state.runCreatePermission
      );
    },

    itemAvailableActions() {
      return [
        {
          value: "upload",
          text: this.$t("global.action.upload"),
          disabled: !this.$store.state.runCreatePermission,
        },
        {
          value: "pick-version",
          text: this.$t("global.action.pick-version"),
          disabled: !this.$store.state.runCreatePermission,
        },
        {
          value: "pick-latest-version",
          text: this.$t("global.action.pick-latest-version"),
          disabled: !this.$store.state.runCreatePermission,
          divider: true,
        },
        { value: "download", text: this.$t("global.action.download") },
      ];
    },

    itemUnavailableActions() {
      return [
        {
          value: "upload",
          text: this.$t("global.action.upload"),
          disabled: !this.$store.state.runCreatePermission,
        },
        {
          value: "pick-version",
          text: this.$t("global.action.pick-version"),
          disabled: !this.$store.state.runCreatePermission,
        },
        {
          value: "pick-latest-version",
          text: this.$t("global.action.pick-latest-version"),
          disabled: !this.$store.state.runCreatePermission,
        },
      ];
    },

    isRunning() {
      return this.$store.getters.isStatusRunning(this.run.status);
    },

    isFinished() {
      return this.$store.getters.isStatusFinished(this.run.status);
    },

    itemsFiltered() {
      let items = [...this.items];
      if (!this.showAll) {
        items = this.items.filter((item) => item.userInput || !item.available);
      }

      if (!this.showPath && items && items.length) {
        items = items.map((item) => {
          let itemWithPathRemove = { ...item };
          let filename = itemWithPathRemove.name;

          itemWithPathRemove.name =
            filename && filename.length
              ? `${filename}`.substring(filename.lastIndexOf("/") + 1)
              : filename;
          return itemWithPathRemove;
        });
      }
      return items;
    },
  },

  watch: {
    run(newValue, oldValue) {
      if (
        oldValue.status !== newValue.status &&
        this.$store.getters.isStatusPreRun(oldValue.status)
      ) {
        this.loadTableData();
      }
    },
  },

  methods: {
    onCancelUploadZip() {
      this.showZipUploadInput = false;
    },

    onUploadAsZip() {
      this.showZipUploadInput = !this.showZipUploadInput;
    },

    async onConfirmUploadAsZip() {
      this.uploadingZip = true;
      const formData = new FormData();

      formData.append("zipFile", this.uploadZip);
      formData.append("runInfoId", this.run.infoId);
      formData.append("environment", this.run.fileEnvName);

      const zipUploadReport = await postForm(
        "/simulationRun/upload-zip",
        formData
      )
        .then((r) => {
          this.uploadingZip = false;
          this.showZipUploadInput = false;
          this.uploadZip = undefined;
          this.showZipUploadReport = true;
          this.updateFileInfosWhileBusy();
          return r;
        })
        .catch((error) => {
          this.uploadingZip = false;
          this.showZipUploadInput = false;
          this.$store.commit("showError", error);
          this.uploadZip = undefined;
        });

      this.zipReportItems = Object.entries(zipUploadReport).map((item) => {
        return {
          file: item[0],
          status: this.translateStatus(item[1]),
          uploaded: this.isUploaded(item[1]),
        };
      });
    },

    isUploaded(status) {
      switch (status) {
        case "NO_MATCH":
          return false;
        case "IDENTICAL":
          return false;
        case "WARNING":
          return true;
        case "ERROR":
          return false;
        default:
          return true;
      }
    },

    translateStatus(status) {
      switch (status) {
        case "NO_MATCH":
          return (
            this.$t("global.concepts.not-imported") +
            ": " +
            this.$t("global.concepts.no-match")
          );
        case "IDENTICAL":
          return (
            this.$t("global.concepts.not-imported") +
            ": " +
            this.$t("global.concepts.identical")
          );
        case "WARNING":
          return (
            this.$t("global.concepts.imported") +
            ": " +
            this.$t("global.concepts.import-warnings")
          );
        case "ERROR":
          return (
            this.$t("global.concepts.not-imported") +
            ": " +
            this.$t("global.concepts.import-errors")
          );
        default:
          return this.$t("global.concepts.imported");
      }
    },

    invalidFileMessage(messages) {
      return messages.join("\n");
    },

    async getFileInfoes() {
      const path = "/simulationRun/run-info/" + this.run.infoId + "/file-info";

      return await get(path).catch((err) =>
        this.$store.commit("showError", err)
      );
    },

    async loadTableData() {
      this.busy = true;
      this.items = [];
      this.fileInfoes = await this.getFileInfoes();

      this.fileInfoes?.forEach((fileInfo) => {
        const item = this.fileInfoToTableItem(fileInfo);
        this.items.push(item);
      });
      this.busy = false;
    },

    fileInfoToTableItem(fileInfo) {
      const item = {};

      item.id = fileInfo.id;
      item.fileId = fileInfo.fileId;
      item.env = fileInfo.fileEnvLabel;
      item.envColor = fileInfo.fileEnvColor;
      item.envName = fileInfo.fileEnvName;
      item.envIsLocal = fileInfo.fileEnvIsLocal;
      item.name = fileInfo.name;
      item.fullName = fileInfo.name;
      item.available = fileInfo.available;
      item.userInput = fileInfo.userInput;
      item.busy = fileInfo.busy;
      item.invalidDataMessages = fileInfo.invalidDataMessages;

      if (fileInfo.fileVersionSize) {
        item.size = `${Math.round(fileInfo.fileVersionSize / 1000)} KB`;
        item.version = fileInfo.fileVersionNumber;
        item.fileUrl = `simulationRun/file-info/${fileInfo.id}/download`;
      }
      return item;
    },

    onCancel() {
      this.showUpload = false;
    },

    onDownload(href) {
      downloadFile(href).catch((err) => {
        this.$store.commit("showError", err);
      });
    },

    onPickVersion(item) {
      if (this.$store.state.runCreatePermission) {
        this.selectedFileInfo = this.fileInfoes.find(
          (info) => info.id === item.id
        );
        this.pickVersionFileName = item.fullName;
        this.pickVersionEnv = item.envName;
        this.showFileVersion = true;
      }
    },

    onVersionPicked(versionId) {
      if (this.selectedFileInfo) {
        put(
          "/simulationRun/pick-file-version?runInfoId=" +
            this.run.infoId +
            "&fileInfoId=" +
            this.selectedFileInfo.id +
            "&pickedFileVersionId=" +
            versionId
        )
          .then(() => this.updateFileInfosWhileBusy())
          .catch((err) => this.$store.commit("showError", err));
      }
    },

    onPickLatestVersion(item) {
      this.selectedFileInfo = this.fileInfoes.find(
        (info) => info.id === item.id
      );
      put(
        "/simulationRun/pick-latest-version?runInfoId=" +
          this.run.infoId +
          "&fileInfoId=" +
          item.id
      )
        .then(() => this.updateFileInfosWhileBusy())
        .catch((err) => this.$store.commit("showError", err));
    },

    async updateFileInfosWhileBusy() {
      let busy = false;
      do {
        busy = false;
        const fileInfos = await this.getFileInfoes();
        if (fileInfos) {
          fileInfos.forEach((newFileInfo) => {
            const row = this.items.findIndex((i) => i.id === newFileInfo.id);
            this.items[row] = this.fileInfoToTableItem(newFileInfo);
            this.items = [...this.items]; // this is necessary to trigger an update of itemsFiltered
            if (newFileInfo.busy) {
              busy = true;
            }
          });
        }
        await this.sleep(1800);
      } while (busy);
    },

    async updateFileInfoWhileBusy() {
      let newFileInfo;
      const selectedFileInfoId = this.selectedFileInfo.id;
      do {
        newFileInfo = await get(
          "/simulationRun/file-info/" + selectedFileInfoId
        ).catch((err) => this.$store.commit("showError", err));
        // It is necessary to find the row every time because it is possible that the list of items has been updated while this loop is running
        const row = this.items.findIndex((i) => i.id === selectedFileInfoId);
        this.items[row] = this.fileInfoToTableItem(newFileInfo);
        this.items = [...this.items]; // this is necessary to trigger an update of itemsFiltered
        await this.sleep(1800);
      } while (newFileInfo.busy);
      const row = this.items.findIndex((i) => i.id === selectedFileInfoId);
      this.items[row] = this.fileInfoToTableItem(newFileInfo);
      this.items = [...this.items]; // this is necessary to trigger an update of itemsFiltered
    },

    sleep(ms) {
      return new Promise((res) => setTimeout(res, ms));
    },

    onUploadAction(record) {
      if (this.$store.state.runCreatePermission) {
        this.selectedFileInfo = this.fileInfoes.find(
          (info) => info.id === record.id
        );
        this.showUpload = true;
      }
    },

    async onUpload(uploadParameters, force) {
      if (this.selectedFileInfo) {
        this.uploading = true;
        const formData = new FormData();

        this.uploadParameters.fileName = uploadParameters.fileName;
        this.uploadParameters.environment = uploadParameters.environment;
        this.uploadParameters.file = uploadParameters.file;
        this.uploadParameters.comment = uploadParameters.comment;

        formData.append("fileName", uploadParameters.fileName);
        formData.append("environment", uploadParameters.environment);
        formData.append("file", uploadParameters.file);
        formData.append("fileInfoId", this.selectedFileInfo.id);
        formData.append("force", force ? true : false);
        formData.append("comment", uploadParameters.comment);

        await postForm("/simulationRun/uploadFile", formData)
          .then((res) => {
            this.uploading = false;
            this.uploaded(res);
          })
          .catch((error) => {
            this.uploading = false;
            this.$store.commit("showError", error);
          });
      }
    },

    async uploaded(res) {
      this.showUpload = false;
      if (res.messages?.length > 0) {
        this.showWarnings = true;
        this.warnings = res.messages;
      } else {
        this.showWarnings = false;
        this.updateFileInfosWhileBusy();
      }
    },

    onConfirmCancel() {
      this.showWarnings = false;
    },

    onConfirmUpload() {
      if (this.uploadParameters) {
        this.onUpload(this.uploadParameters, true);
      }
    },
  },
  created() {
    if (this.run?.infoId) {
      this.loadTableData();
    }
  },
};
</script>
