<template>
  <module-template
    id="scrolltainer"
    :title="$t('global.menu.runs')"
    :canCreate="$store.state.runCreatePermission"
    :createToolTip="
      $t('global.header.create', [$t('global.run.run').toLowerCase()])
    "
    :can-delete="$store.state.runDeletePermission && selectedRows.length > 0"
    :tree-enabled="treeEnabled"
    can-clear-filters
    has-tree-toggle
    @newItem="create"
    @deleteItem="onDelete"
    @toggleTree="onTreeToggle"
    @clearFilters="onClear"
  >
    <v-row dense align="center">
      <v-col :cols="treeEnabled ? 10 : 12" :offset="treeEnabled ? 2 : 0">
        <v-row dense>
          <v-col cols="2" v-for="([key, options], i) in filterOptions" :key="i">
            <auto-complete-filter
              :items="options"
              v-model="keyFilter['key' + (i + 1)]"
              @change="onTopFilter"
              :disabled="treeEnabled"
              :label="key"
            >
            </auto-complete-filter
          ></v-col>

          <v-col cols="2">
            <auto-complete-filter
              :items="fileEnvNames"
              :label="$t('global.environment.environment')"
              v-model="topSelectedFileEnv"
              :disabled="treeEnabled"
              @change="onTopFilter"
            ></auto-complete-filter>
          </v-col>

          <v-col cols="2" align-self="center"
            ><v-checkbox
              class="mt-0 pt-0"
              :label="$t('global.concepts.my-runs')"
              @change="onTopFilter"
              v-model="myRuns"
              hide-details
            ></v-checkbox
          ></v-col>

          <v-col cols="1"
            ><v-text-field
              :label="$t('global.action.go-to-run')"
              outlined
              v-model="idFilter"
              :rules="[noTagsRule]"
              prepend-icon="#"
              persistent-placeholder
              @keyup.enter="goToRun"
            >
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-btn color="primary" @click="goToRun" :disabled="!idFilter">{{
              $t("global.action.go")
            }}</v-btn>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="2"
            ><auto-complete-filter
              :items="moduleNames"
              @change="onModuleFilter"
              :label="$t('global.modules.module')"
              v-model="moduleFilter"
            ></auto-complete-filter
          ></v-col>

          <v-col cols="2"
            ><auto-complete-filter
              :disabled="!moduleFilter"
              :items="moduleVersions"
              @change="onTopFilter"
              :label="$t('global.modules.module-version')"
              v-model="moduleVersionFilter"
            ></auto-complete-filter
          ></v-col>

          <v-col cols="2"
            ><auto-complete-filter
              :items="statuses"
              @change="onTopFilter"
              :label="$t('global.concepts.status')"
              v-model="statusFilter"
            ></auto-complete-filter
          ></v-col>

          <v-col cols="2"
            ><v-text-field
              :label="$t('global.concepts.title')"
              outlined
              v-model="titleFilter"
              :rules="[noTagsRule]"
              persistent-placeholder
              @keyup.enter="onTopFilter"
            ></v-text-field
          ></v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="2" v-if="treeEnabled">
        <auto-complete-filter
          :items="fileEnvNames"
          :label="$t('global.environment.environment')"
          v-model="treeSelectedFileEnv"
          @change="onEnvSelect"
          :close="false"
        ></auto-complete-filter>
        <v-treeview
          :items="[folderTree]"
          :active.sync="treeActive"
          active-class="ooliba-basic-table-tree-item-active"
          @update:active="onTreeFilter"
          activatable
          dense
          hoverable
          transition
        >
          <template v-slot:prepend="{ open }">
            <v-icon small>
              {{ open ? "pic-folder-opened-fill" : "pic-folder-fill" }}
            </v-icon>
          </template>
          <template #label="{ item, active }">
            <v-chip small v-if="active" class="tree-chip">{{
              item.name
            }}</v-chip>
            <v-chip small class="transparent-tree-chip" v-else>
              {{ item.name }}</v-chip
            ></template
          >
        </v-treeview>
      </v-col>

      <v-col :cols="treeEnabled ? 10 : 12">
        <ooliba-basic-table
          v-model="selectedRows"
          :headers="headers"
          :items="items"
          :loading="busy"
          sort-by="id"
          sort-desc
          must-sort
          :show-select="$store.state.runDeletePermission"
          :server-items-length="numItems"
          @row-clicked="onRowClick"
          @options-changed="onOptionsChanged"
        >
          <template #[`item.bpInstance`]="{ item }">
            <v-tooltip bottom v-if="item.bpInstance">
              <template v-slot:activator="{ on }">
                <v-chip color="orange" dark small v-on="on">
                  {{ item.bpInstance.split(":")[0] }}
                </v-chip>
              </template>
              <span>{{ item.bpInstance.split(":")[1] }}</span>
            </v-tooltip>
          </template>

          <template #[`item.dateCreated`]="{ item }">
            {{ formatDate(item.dateCreated) }}
          </template>

          <template #[`item.status`]="{ item }">
            <template>
              <v-progress-circular
                v-if="item.statusIcon === 'RUNNING'"
                indeterminate
                color="blue"
                size="20"
                width="2"
              ></v-progress-circular>
              <basic-tooltip
                v-else
                :color="item.statusColor"
                :icon="item.statusIcon"
                :text="item.translatedStatus"
              />
            </template>
          </template>
        </ooliba-basic-table>
      </v-col>
    </v-row>
  </module-template>
</template>

<script>
import { get, remove } from "@/model/api";
import { noTags } from "@/model/rules";
import { formatDate } from "@/model/util";

import BasicTooltip from "@/components/BasicTooltip";
import OolibaBasicTable from "@/components/OolibaBasicTable";
import AutoCompleteFilter from "@/components/AutoCompleteFilter";
import ModuleTemplate from "@/components/layout/ModuleTemplate";

export default {
  name: "RunList",

  components: {
    BasicTooltip,
    ModuleTemplate,
    OolibaBasicTable,
    AutoCompleteFilter,
  },

  data() {
    return {
      busy: true,

      filterOptions: [],
      keyFilter: {},
      treeEnabled: false,
      fileEnvNames: [],
      topSelectedFileEnv: undefined,
      treeSelectedFileEnv: undefined,
      folderTree: {},
      treeActive: [],
      treeKeyFilter: {},
      moduleFilter: undefined,
      moduleNames: [],
      moduleVersionFilter: undefined,
      moduleVersions: [],
      statuses: [],
      statusFilter: undefined,
      titleFilter: undefined,
      idFilter: undefined,
      myRuns: undefined,

      selectedRows: [],

      items: [],
      runs: [],
      options: {},
      numItems: 0,

      noTagsRule: noTags(this),
    };
  },

  computed: {
    headers() {
      const headers = [
        {
          text: "Id",
          value: "id",
          noHideable: true,
        },
        {
          text: this.$t("global.concepts.title"),
          value: "title",
          width: "170px",
          noHideable: true,
        },
        {
          text: this.$t("global.modules.module"),
          value: "module",
          noHideable: true,
          sortable: false,
        },
        {
          text: this.$t("global.modules.module-version"),
          value: "releaseVersion",
          sortable: false,
        },
        {
          text: this.$t("global.run.simulation"),
          value: "simulation",
          sortable: false,
        },
        {
          text: this.$t("global.concepts.created-on"),
          value: "dateCreated",
          width: "170px",
        },
        {
          text: this.$t("global.business-process.task"),
          value: "task",
          hidden: true,
          width: "170px",
          sortable: false,
        },

        {
          text: this.$t("global.concepts.status"),
          value: "status",
          sortable: false,
        },

        {
          text: this.$t("global.business-process.bp-instance"),
          value: "bpInstance",
          align: "center",
          sortable: false,
        },
      ];

      this.filterOptions.forEach(([orgKey], i) => {
        headers.push({
          text: orgKey,
          value: "key" + (i + 1),
          treeFilter: true,
          hidden: true,
          sortable: false,
          treeSortDesc: orgKey.key === "year",
        });
      });

      return headers;
    },
  },

  methods: {
    formatDate,
    onClear() {
      this.treeActive = [];
      this.treeKeyFilter = {};
      this.keyFilter = {};
      this.topSelectedFileEnv = undefined;
      this.treeSelectedFileEnv = undefined;
      this.moduleFilter = undefined;
      this.moduleVersionFilter = undefined;
      this.statusFilter = undefined;
      this.titleFilter = undefined;
      this.myRuns = undefined;
      this.onTopFilter();
    },

    async onTopFilter() {
      if (typeof this.noTagsRule(this.titleFilter) === "string") {
        return;
      }
      this.refreshItems(
        this.options,
        this.treeEnabled ? this.treeKeyFilter : this.keyFilter
      );
    },

    async goToRun() {
      if (!this.idFilter) return;
      if (typeof this.noTagsRule(this.idFilter) === "string") {
        return;
      }
      const run = await get("/simulationRun?id=" + this.idFilter).catch((e) =>
        this.onError(e)
      );
      if (run) {
        this.$router.push({ name: "Run", params: { runId: this.idFilter } });
      }
    },

    async onModuleFilter() {
      this.moduleVersionFilter = undefined;

      if (this.moduleFilter) {
        this.moduleVersions = await get("release/versions/" + this.moduleFilter)
          .then((versions) => versions.map((v) => v.versionString))
          .catch((error) => {
            this.$store.commit("showError", error);
          });
      }
      this.onTopFilter();
    },

    async onOptionsChanged(options) {
      if (this.treeEnabled) {
        this.refreshItems(options, this.treeKeyFilter);
      } else {
        this.refreshItems(options, this.keyFilter);
      }
    },

    async onTreeFilter(val) {
      this.treeKeyFilter = {};
      if (val && val[0]) {
        val[0].split(";").forEach((keyVal, i) => {
          const key = "key" + (i + 1);
          this.treeKeyFilter[key] = keyVal;
        });
      }
      this.refreshItems(this.options, this.treeKeyFilter);
    },

    async onEnvSelect() {
      this.refreshFolderTree(this.treeSelectedFileEnv);
      this.treeKeyFilter = {};
      this.treeActive = [];
      this.refreshItems(this.options, this.treeKeyFilter);
    },

    async onTreeToggle() {
      this.treeEnabled = !this.treeEnabled;
      if (this.treeEnabled) {
        this.topSelectedFileEnv = undefined;
        this.treeSelectedFileEnv = "default";
        this.keyFilter = {};
        this.refreshItems(this.options, this.keyFilter);
      } else {
        this.treeActive = [];
        this.topSelectedFileEnv = undefined;
        this.treeSelectedFileEnv = undefined;
        this.refreshItems(this.options, this.keyFilter);
      }
    },

    async refreshItems(options, keyFilter) {
      this.options = options;
      this.busy = true;
      const runs = await this.getRuns(options, keyFilter);
      if (runs) {
        this.setItems(runs.content);
        this.numItems = runs.totalElements;
      }
      this.busy = false;
    },

    async refreshFolderTree(env) {
      this.folderTree = await get(
        "simulationRun/organisation-json?envName=" + encodeURIComponent(env)
      ).catch((error) => {
        this.onError(error);
      });
    },

    create() {
      this.$router.push({ name: "Create Run" });
    },

    setItems(runs) {
      this.items = [];
      if (runs) {
        runs.forEach((value) => {
          const item = {};

          item.id = value.id;
          item.title = value.title;
          item.module = value.module;
          item.releaseVersion = `v${value.moduleVersion}`;
          item.simulation = value.simulation;
          item.dateCreated = value.dateCreated;
          item.task = value.taskVersionName;
          item.status = value.running ? value.progress : value.status;
          item.bpInstance = value.bpTitle
            ? "#" + value.bpId + ":" + value.bpTitle
            : "";

          let statusIcon = this.$store.getters.getStatusIcon(value.status);
          item.statusColor = statusIcon.statusColor;
          item.statusIcon = statusIcon.statusIcon;
          item.translatedStatus = value.translatedStatus;

          let i = 9;
          while (this.headers[i]) {
            item[this.headers[i].value] = value["key" + (i - 8)];
            i++;
          }
          item.isSelectable = !value.running;

          this.items.push(item);
        });
      }
    },

    async onDelete() {
      this.busy = true;

      const params = this.selectedRows.map((item) => item.id).join("&id=");
      const url = "/simulationRun?id=" + params;
      await remove(url)
        .then(() => this.refreshItems(this.options, this.keyFilter))
        .catch((error) => this.onError(error));
      this.selectedRows = [];

      this.busy = false;
    },

    onError(error) {
      this.$store.commit("showError", error);
    },

    onRowClick(record) {
      if (record.id) {
        const runId = record.id;
        this.$router.push({ name: "Run", params: { runId: runId } });
      }
    },

    async getRuns(options, keyFilter) {
      var sortBy = options.sortBy.length > 0 ? options.sortBy[0] : "id";
      var sortDesc = options.sortDesc.length > 0 ? options.sortDesc[0] : true;
      var path =
        "/simulationRun/list?page=" +
        options.page +
        "&itemsPerPage=" +
        options.itemsPerPage +
        "&sortBy=" +
        sortBy +
        "&sortDesc=" +
        sortDesc;

      if (this.topSelectedFileEnv) {
        path += "&env=" + encodeURIComponent(this.topSelectedFileEnv);
      } else if (this.treeSelectedFileEnv) {
        path += "&env=" + encodeURIComponent(this.treeSelectedFileEnv);
      }
      if (keyFilter["key1"]) {
        path += "&key1=" + encodeURIComponent(keyFilter["key1"]);
      }
      if (keyFilter["key2"]) {
        path += "&key2=" + encodeURIComponent(keyFilter["key2"]);
      }
      if (keyFilter["key3"]) {
        path += "&key3=" + encodeURIComponent(keyFilter["key3"]);
      }
      if (keyFilter["key4"]) {
        path += "&key4=" + encodeURIComponent(keyFilter["key4"]);
      }
      if (keyFilter["key5"]) {
        path += "&key5=" + encodeURIComponent(keyFilter["key5"]);
      }
      if (this.moduleFilter) {
        path += "&module=" + encodeURIComponent(this.moduleFilter);
      }
      if (this.moduleVersionFilter) {
        path +=
          "&moduleVersion=" + encodeURIComponent(this.moduleVersionFilter);
      }
      if (this.statusFilter && this.statusFilter.length > 0) {
        path += "&status=";
        this.statusFilter.forEach((status, i) => {
          path += (i > 0 ? "," : "") + encodeURIComponent(status);
        });
      }
      if (this.titleFilter) {
        path += "&title=" + encodeURIComponent(this.titleFilter);
      }
      if (this.myRuns) {
        path += "&myRuns=" + encodeURIComponent(this.myRuns);
      }

      return await get(path).catch((error) => {
        this.onError(error);
      });
    },
  },

  async getModules() {
    return await get("release/latest-versions").catch((error) => {
      this.$store.commit("showError", error);
    });
  },

  async created() {
    const options = await get("/simulationRun/filter-options").catch(
      (error) => {
        this.onError(error);
      }
    );

    if (options) {
      this.filterOptions = Object.entries(options);
    }

    this.fileEnvNames = await get("/file-env/names").catch((error) => {
      this.onError(error);
    });

    this.moduleNames = await get("release/latest-versions")
      .then((modules) => modules.map((m) => m.moduleName))
      .catch((error) => {
        this.$store.commit("showError", error);
      });

    this.statuses = await get("simulationRun/statuses").catch((error) => {
      this.onError(error);
    });
  },
};
</script>
